import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const showDataForm    = new FormData();

    showDataForm.append("id", id);
    showDataForm.append('userId', userId);
    showDataForm.append('type_page', origin);
    showDataForm.append('edit_data', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data); 
    //     SetListDataSingle(origin, data[origin]);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Reactivate data
export function Reg_Reactivate(type, origin, idData, userId, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', origin);
    showDataForm.append('edit_data', type);

    showDataForm.append('id', idData);
    showDataForm.append('userId', userId);

    // Axios({
    //     url     : process.env.REACT_APP_API_URL + 'php/register.php',
    //     mode    : 'no-cors',
    //     method  : 'POST',
    //     data    : showDataForm
    // })
    // .then(response => {
    //     SetListDataSingle(origin, response.data[origin]);
    //     CallbackSuccess();

    // }).catch((error)=>{

    //     CallbackError();
    // })

    fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(showDataForm)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Erro na resposta');
        }
    })
    .then(data => {
        SetListDataSingle(origin, data[origin]);
        CallbackSuccess();
    })
    .catch(error => {
        console.log(error);
        CallbackError();
    });
}
// Alt position contents
export function Reg_AltPosition(type_page, idPage, id, type, order, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();
    showDataForm.append('type_page', type_page);
    showDataForm.append('edit_data', 'alt_position');

    showDataForm.append('idPage', idPage);
    showDataForm.append('idContents', id);
    showDataForm.append('type', type);
    showDataForm.append('order', order);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle(type_page, response.data[type_page]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle(type_page, data[type_page]);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// About Chroma
export function Reg_Config(listEmail, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();
    showDataForm.append('type_page', 'config_email');
    showDataForm.append('edit_data', 'edit_or_register_data');

    listEmail.forEach((element, index) => {
        showDataForm.append("email_id[]", element.id);
        showDataForm.append("email_type[]", element.type);
        showDataForm.append("email_email[]", element.email);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('config_email', response.data.config_email);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle('config_email', data.config_email);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// About Chroma
export function Reg_About(homeTitle, homeType, homeFile, homeImg, servColor, servTitle, servSubtitle, servImg, servList, starColor, starTitle, starList, starFooter, clientColor, brandList, textEnd, endColor, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();
    showDataForm.append('type_page', 'about_chroma');
    showDataForm.append('edit_data', 'edit_or_register_data');

    showDataForm.append("homeTitle", homeTitle);
    showDataForm.append("homeType", homeType);
    showDataForm.append("homeFile", homeFile);
    showDataForm.append("homeImg", homeImg);
    showDataForm.append("servColor", servColor);
    showDataForm.append("servTitle", servTitle);
    showDataForm.append("servSubtitle", servSubtitle);
    showDataForm.append("servImg", servImg);
    showDataForm.append("starColor", starColor);
    showDataForm.append("starTitle", starTitle);
    showDataForm.append("clientColor", clientColor);
    showDataForm.append("textEnd", textEnd);
    showDataForm.append("endColor", endColor);

    servList.forEach((element, index) => {
        showDataForm.append("serv_id[]", element.id);
        showDataForm.append("serv_title[]", element.title);
        showDataForm.append("serv_text[]", element.text);
    });

    starList.forEach((element, index) => {
        showDataForm.append("star_id[]", element.id);
        showDataForm.append("star_title[]", element.title);
        showDataForm.append("star_text[]", element.text);
        showDataForm.append("star_star[]", element.star);
    });

    starFooter.forEach((element, index) => {
        showDataForm.append("footer_id[]", element.id);
        showDataForm.append("footer_sigla[]", element.sigla);
        showDataForm.append("footer_number[]", element.number);
        showDataForm.append("footer_title[]", element.title);
    });

    brandList.forEach((element, index) => {
        showDataForm.append("brand_id[]", element.id);
        showDataForm.append("brand_title[]", element.title);
        showDataForm.append("brand_img_" + index, element.img);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('about_chroma', response.data.about_chroma);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle('about_chroma', data.about_chroma);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// edit or register client
export function Reg_Client(idUser, idPage, name, logoClient, corporateName, cnpj, actingArea, customerSince, note, contact, CallbackSuccess, CallbackError){
    
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'client');
    showDataForm.append('edit_data', 'edit_or_register_data');

    showDataForm.append('idUser', idUser);
    showDataForm.append('idPage', idPage);
    showDataForm.append('name', name);
    showDataForm.append('logoClient', logoClient);
    showDataForm.append('corporateName', corporateName);
    showDataForm.append('cnpj', cnpj);
    showDataForm.append('actingArea', actingArea);
    showDataForm.append('customerSince', customerSince);
    showDataForm.append('note', note);

    contact.forEach((element, index) => {
        showDataForm.append('contact_id[]', element.id);
        showDataForm.append('contact_name[]', element.name);
        showDataForm.append('contact_email[]', element.email);
        showDataForm.append('contact_phone[]', element.phone);
        showDataForm.append('contact_office[]', element.office);
        showDataForm.append('contact_file_' + index, element.file);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        method  : 'POST',
        data    : showDataForm,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    .then(response => {

        console.log(response.data); 
        
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListPag('currentPageId', data.idPage);
    //     SetListDataSingle('client', data.client);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Register contact
export function Reg_NewContact(idPage, name, email, phone, position, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'client');
    showDataForm.append('edit_data', 'new_contact');

    showDataForm.append('idPage', idPage);
    showDataForm.append('name', name);
    showDataForm.append('email', email);
    showDataForm.append('phone', phone);
    showDataForm.append('position', position);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {

        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle('client', data.client);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// Edit or register tag
export function Reg_Tag(id, type, name, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'portfolio');
    showDataForm.append('edit_data', 'edit_or_register_tag');

    showDataForm.append('id', id);
    showDataForm.append('type', type);
    showDataForm.append('name', name);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        SetListDataSingle('portfolio_tag', response.data.portfolio_tag);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    });

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle('portfolio_tag', data.portfolio_tag);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// New proposal
export function Reg_Proposal(idUser, title, client, newClient, description, projectAdd, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();
    showDataForm.append('type_page', 'portfolio');
    showDataForm.append('edit_data', 'new_proposal');

    showDataForm.append("idUser", idUser);
    showDataForm.append("title", title);
    showDataForm.append("client", client);
    showDataForm.append("newClient", newClient);
    showDataForm.append("description", description);

    projectAdd.forEach(element => {
        showDataForm.append("id_project[]", element.id);
        showDataForm.append("id_text[]", element.text);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        
        SetListDataSingle('proposal', response.data.proposal);
        SetListDataSingle('client', response.data.client);

        CallbackSuccess();
    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle('proposal', data.proposal);
    //     SetListDataSingle('client', data.client);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Register client
export function Reg_NewClient(userId, id, origin, name, corporateName, cnpj, actingArea, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', origin);
    showDataForm.append('edit_data', 'new_client');

    showDataForm.append('userId', userId);
    showDataForm.append('idPage', id);
    showDataForm.append('origin', origin);
    showDataForm.append('name', name);
    showDataForm.append('corporateName', corporateName);
    showDataForm.append('cnpj', cnpj);
    showDataForm.append('actingArea', actingArea);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);
    //     SetListDataSingle(origin, data[origin]);
    //     SetListDataSingle('client', data.client);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Edit ou register portfolio
export function Reg_Portfolio(userId, idPage, project, year, colorBg, text, typeFile, file, positionX, positionY, width, height, typeCrop, showWidth, widthReal, contents, listLink, datasheet, restricted, client, listServices, listTag, coverTitle, coverSubtitle, coverText, coverColor, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'portfolio');
    showDataForm.append('edit_data', 'edit_or_new_portfolio');

    showDataForm.append("userId", userId);
    showDataForm.append("id_page", idPage);

    showDataForm.append("project", project);
    showDataForm.append("year", year);
    showDataForm.append("color_bg", colorBg);
    showDataForm.append("text", text);
    showDataForm.append("type_file", typeFile);

    console.log(file);
    
    showDataForm.append("file", file);
    showDataForm.append("positionX", positionX);
    showDataForm.append("positionY", positionY);
    showDataForm.append("width", width);
    showDataForm.append("height", height);
    showDataForm.append("typeCrop", typeCrop);
    showDataForm.append("showWidth", showWidth);
    showDataForm.append("widthReal", widthReal);

    showDataForm.append("datasheet", datasheet);
    showDataForm.append("restricted", restricted);
    showDataForm.append("client", client);

    showDataForm.append("cover_title", coverTitle);
    showDataForm.append("cover_subtitle", coverSubtitle);
    showDataForm.append("cover_text", coverText);
    showDataForm.append("cover_color", coverColor);
    
    // links
    listLink.forEach(element => {
        showDataForm.append("links_id[]", element.id);
        showDataForm.append("links_type[]", element.type);
        showDataForm.append("links_title[]", element.title);
        showDataForm.append("links_link[]", element.link);
    });
    
    // services
    listServices.forEach(element => {
        showDataForm.append("services_id[]", element.id);
        showDataForm.append("services_newId[]", element.id_service);
    });
    
    // Tag
    listTag.forEach(element => {
        showDataForm.append("tag_id[]", element.id);
        showDataForm.append("tag_newId[]", element.newID);
    })

    // contents
    contents.forEach((elent_0, index_0) => {
        showDataForm.append('data_id[]', elent_0.id);
        showDataForm.append('data_type[]', elent_0.type);
        showDataForm.append('data_container[]', elent_0.container);
        showDataForm.append('data_order[]', elent_0.order_);
        showDataForm.append('data_width[]', elent_0.width);
        showDataForm.append('data_height[]', elent_0.height);

        elent_0.contents.forEach((elent_1, index_1) => {
            showDataForm.append('index_0[]', index_0);
            showDataForm.append('index_1[]', index_1);
            showDataForm.append('contents_id_' + index_0 + '_' + index_1, elent_1.id);
            showDataForm.append('contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
            showDataForm.append('contents_type_' + index_0 + '_' + index_1, elent_1.type);
            showDataForm.append('contents_width_' + index_0 + '_' + index_1, elent_1.width);
            showDataForm.append('contents_height_' + index_0 + '_' + index_1, elent_1.height);

            showDataForm.append('contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
            showDataForm.append('contents_link_' + index_0 + '_' + index_1, elent_1.link);
            showDataForm.append('contents_title_' + index_0 + '_' + index_1, elent_1.title);
            showDataForm.append('contents_subtitle_' + index_0 + '_' + index_1, elent_1.subtitle);
            showDataForm.append('contents_file_' + index_0 + '_' + index_1, elent_1.file);
            showDataForm.append('contents_name_' + index_0 + '_' + index_1, elent_1.name);
            showDataForm.append('contents_text_' + index_0 + '_' + index_1, elent_1.text);  
            showDataForm.append('contents_text_1_' + index_0 + '_' + index_1, elent_1.text_1); 
            showDataForm.append('contents_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);  

            showDataForm.append('contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
            showDataForm.append('contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
            showDataForm.append('contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
            showDataForm.append('contents_data_width_' + index_0 + '_' + index_1, elent_1.crop_width);
            showDataForm.append('contents_data_height_' + index_0 + '_' + index_1, elent_1.crop_height);
            showDataForm.append('contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
            showDataForm.append('contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);

            if(elent_1.gallery){
                showDataForm.append('gallery_qtd_' + index_0 + '_' + index_1, elent_1.gallery.length);
                elent_1.gallery.forEach((elent_2, index_2) => {                    
                    showDataForm.append('gallery_index_0_' + index_0 + '_' + index_1 + '_' + index_2, index_0);
                    showDataForm.append('gallery_index_1_' + index_0 + '_' + index_1 + '_' + index_2, index_1);

                    showDataForm.append('gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                    showDataForm.append('gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                    showDataForm.append('gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                    showDataForm.append('gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                });
            }
        });
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {        
        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);

        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('portfolio', response.data.portfolio);

        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);

    //     SetListPag('currentPageId', data.idPage);
    //     SetListDataSingle('portfolio', data.portfolio);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Register or new proposal
export function Reg_EditOrRegisterProposal(idPage, idUser, typeLogo, color, client, project, description, proposalStatus, showAboutCompany, meeting, newContact, newPortfolio, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();
    const cookies = new Cookies();

    showDataForm.append('type_page', 'proposal');
    showDataForm.append('edit_data', 'edit_or_new_proposal');

    showDataForm.append("id_page", idPage);
    showDataForm.append("idUser", idUser);
    showDataForm.append("typeLogo", typeLogo);
    showDataForm.append("color", color);
    showDataForm.append("client", client);
    showDataForm.append("project", project);
    showDataForm.append("description", description);
    showDataForm.append("proposalStatus", proposalStatus);
    showDataForm.append("showAboutCompany", showAboutCompany);
    showDataForm.append("meeting", meeting);

    if(newPortfolio){
        newPortfolio.forEach(element => {
            showDataForm.append("newPortfolio_id[]", element);
        });
    }
    
    if(newContact){
        newContact.forEach(element => {
            showDataForm.append("new_contact_id[]", element.id);
            showDataForm.append("new_contact_idnew[]", element.idNew);
            showDataForm.append("new_contact_type[]", element.type);
            showDataForm.append("new_contact_name[]", element.name);
            showDataForm.append("new_contact_position[]", element.position);
            showDataForm.append("new_contact_email[]", element.email);
            showDataForm.append("new_contact_phone[]", element.phone);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('proposal', response.data.proposal);
        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
        
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);
    //     SetListPag('currentPageId', data.idPage);

    //     SetListDataSingle('proposal', data.proposal);
    //     SetListDataSingle('client', data.client);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Register Portfolio
export function Reg_AddPortifolio(idUser, idProposal, idPortfolio, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'proposal');
    showDataForm.append('edit_data', 'add_portfolio');

    showDataForm.append('idUser', idUser);
    showDataForm.append('idProposal', idProposal);
    showDataForm.append('idPortfolio', idPortfolio);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        SetListDataSingle('proposal', response.data.proposal);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('proposal', data.proposal);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Add portfolio in proposal
export function Reg_AddPortifolioProposal(idUser, idProposal, idPortfolio, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'proposal');
    showDataForm.append('edit_data', 'add_new_portfolio');

    showDataForm.append('idUser', idUser);
    showDataForm.append('idProposal', idProposal);

    idPortfolio.forEach(element => {
        showDataForm.append("id_portfolio[]", element);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('proposal', response.data.proposal);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('proposal', data.proposal);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const showDataForm = new FormData();

    showDataForm.append('type_page', 'access_dash');
    showDataForm.append('edit_data', 'edit_profile');

    showDataForm.append('userId', userId);
    showDataForm.append('idPage', idPage);
    showDataForm.append('name', name);
    showDataForm.append('email', email);
    showDataForm.append('pass', pass);
    showDataForm.append('file', file);
    showDataForm.append('nivel', nivel);
    showDataForm.append('note', note);

    addPage.forEach((element, index) => {
        showDataForm.append('page_value[]', element.value);
        showDataForm.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('access_dash', data.access_dash);
    //     if(data.idPage !=0){
    //         SetListPag('currentPageId', data.idPage);
    //     }

    //     if(userId == idPage){
    //         const newData = data.access_dash.find(item => item.id == userId);
    //         cookies.set(typeCookieEmail, email, '/', cookiesRegister);
    //         if(pass !=''){
    //             cookies.set(typeCookiePassw, data.pass, '/', cookiesRegister);
    //         }

    //         SetUserData('id', newData['id']);
    //         SetUserData('file', newData['file']);
    //         SetUserData('name', newData['name']);
    //         SetUserData('email', newData['email']);
    //         SetUserData('access', newData['access']);
    //         SetUserData('page', newData['page']);
    //     }
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// add td
export function Reg_AddTd(userId, id_td, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'access_dash');
    showDataForm.append('edit_data', 'add_td_page');

    showDataForm.append('userId', userId);
    showDataForm.append('id_td', id_td);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('access_dash', data.access_dash);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// Edit or register category
export function Reg_Category(id, origin, name, typeFile, video, color, file, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'services_category');
    showDataForm.append('edit_data', 'edit_or_register_category');

    showDataForm.append('id', id);
    showDataForm.append('name', name);
    showDataForm.append('type_file', typeFile);
    showDataForm.append('video', video);
    showDataForm.append('color', color);
    showDataForm.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        
        SetListDataSingle('services_category', response.data.services_category);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('services_category', data.services_category);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Register new combo
export function Reg_NewCombo(idPage, name, services, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'services_combo');
    showDataForm.append('edit_data', 'edit_or_register_combo');

    showDataForm.append('idPage', idPage);
    showDataForm.append('name', name);

    services.forEach(element => {
        showDataForm.append('id[]', element.id);
        showDataForm.append('idNew[]', element.idNew);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('services_combo', response.data.services_combo);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);
    //     SetListPag('currentPageId', data.idPage);
    //     SetListDataSingle('services_combo', data.services_combo);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Edit or register services
export function Reg_Services(idPage, idUser, code, name, cash, textBudg, textPres, link, category, contents, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'services');
    showDataForm.append('edit_data', 'edit_or_register_services');

    showDataForm.append('idPage', idPage);
    showDataForm.append('idUser', idUser);
    showDataForm.append('code', code);
    showDataForm.append('name', name);
    showDataForm.append('cash', cash);
    showDataForm.append('textBudg', textBudg);

    showDataForm.append('textPres', textPres);

    link.forEach((element, index)=> {
        showDataForm.append('link_id[]', element.id);
        showDataForm.append('link[]', element.link);
    });

    category.forEach((element, index)=> {
        showDataForm.append('category_id[]', element.value);
    });

    if(contents.length > 0){
        showDataForm.append('contents_id', contents[0].id);
        showDataForm.append('contents_type', contents[0].type);
            
        showDataForm.append('type_file_1', contents[0].type_file_1);
        showDataForm.append('name_1', contents[0].name_1);
        showDataForm.append('title_1', contents[0].title_1);
        showDataForm.append('video_1', contents[0].video_1);
        showDataForm.append('img_1', contents[0].img_1);
        showDataForm.append('positionX_1', contents[0].positionX_1);
        showDataForm.append('positionY_1', contents[0].positionY_1);
        showDataForm.append('crop_width_1', contents[0].crop_width_1);
        showDataForm.append('crop_height_1', contents[0].crop_height_1);
        showDataForm.append('typeCrop_1', contents[0].typeCrop_1);
        showDataForm.append('showWidth_1', contents[0].showWidth_1);
        showDataForm.append('widthReal_1', contents[0].widthReal_1);
            
        showDataForm.append('type_file_2', contents[0].type_file_2);
        showDataForm.append('name_2', contents[0].name_2);
        showDataForm.append('title_2', contents[0].title_2);
        showDataForm.append('video_2', contents[0].video_2);
        showDataForm.append('img_2', contents[0].img_2);
        showDataForm.append('positionX_2', contents[0].positionX_2);
        showDataForm.append('positionY_2', contents[0].positionY_2);
        showDataForm.append('crop_width_2', contents[0].crop_width_2);
        showDataForm.append('crop_height_2', contents[0].crop_height_2);
        showDataForm.append('typeCrop_2', contents[0].typeCrop_2);
        showDataForm.append('showWidth_2', contents[0].showWidth_2);
        showDataForm.append('widthReal_2', contents[0].widthReal_2);
            
        showDataForm.append('type_file_3', contents[0].type_file_3);
        showDataForm.append('name_3', contents[0].name_3);
        showDataForm.append('title_3', contents[0].title_3);
        showDataForm.append('video_3', contents[0].video_3);
        showDataForm.append('img_3', contents[0].img_3);
        showDataForm.append('positionX_3', contents[0].positionX_3);
        showDataForm.append('positionY_3', contents[0].positionY_3);
        showDataForm.append('crop_width_3', contents[0].crop_width_3);
        showDataForm.append('crop_height_3', contents[0].crop_height_3);
        showDataForm.append('typeCrop_3', contents[0].typeCrop_3);
        showDataForm.append('showWidth_3', contents[0].showWidth_3);
        showDataForm.append('widthReal_3', contents[0].widthReal_3);
            
        showDataForm.append('type_file_4', contents[0].type_file_4);
        showDataForm.append('name_4', contents[0].name_4);
        showDataForm.append('title_4', contents[0].title_4);
        showDataForm.append('video_4', contents[0].video_4);
        showDataForm.append('img_4', contents[0].img_4);
        showDataForm.append('positionX_4', contents[0].positionX_4);
        showDataForm.append('positionY_4', contents[0].positionY_4);
        showDataForm.append('crop_width_4', contents[0].crop_width_4);
        showDataForm.append('crop_height_4', contents[0].crop_height_4);
        showDataForm.append('typeCrop_4', contents[0].typeCrop_4);
        showDataForm.append('showWidth_4', contents[0].showWidth_4);
        showDataForm.append('widthReal_4', contents[0].widthReal_4);
    }else {
        showDataForm.append('contents_id', "");
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('services', response.data.services);
        SetListDataSingle('services_category', response.data.services_category);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data); 
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);
    //     SetListPag('currentPageId', data.idPage);

    //     SetListDataSingle('services', data.services);
    //     SetListDataSingle('services_category', data.services_category);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Copy Service
export function Reg_CopyService(id, idUser, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'services');
    showDataForm.append('edit_data', 'copy_services');

    showDataForm.append('id', id);
    showDataForm.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('services', response.data.services);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);
    //     SetListPag('currentPageId', data.idPage);
    //     SetListDataSingle('services', data.services);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// Register signature
export function Reg_Signature(userId, id, name, file, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget_signature');
    showDataForm.append('edit_data', 'new_or_edit_signature');

    showDataForm.append('userId', userId);
    showDataForm.append('id', id);
    showDataForm.append('name', name);
    showDataForm.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        SetListDataSingle('budget_signature', response.data.budget_signature);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget_signature', data.budget_signature);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Register text fixed
export function Reg_TextFixed(userId, id, type, title, text, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget_text_fixed');
    showDataForm.append('edit_data', 'new_or_edit_text_fixed');

    showDataForm.append('userId', userId);
    showDataForm.append('id', id);
    showDataForm.append('type', type);
    showDataForm.append('title', title);
    showDataForm.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {

        SetListDataSingle('budget_text_fixed', response.data.budget_text_fixed);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget_text_fixed', data.budget_text_fixed);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Edit or register budget
export function Reg_Budget(idPage, idUser, typeDiscount, discount, lock_, version, signature, typeBudget, chanceClose, proposal, client, contact, nameClient, corporateName, cnpj, actingArea, customerSince, contactName, contactEmail, contactPhone, contactOffice, subject, status, dateStatus, newStatus, newColor, billed, dueDate, motive, newMotive, obs, dateStart, dateEnd, mountingStart, mountingEnd, services, textFixed, showGroup, listCategory, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm    = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'edit_or_register_budget');

    showDataForm.append('email', cookies.get(typeCookieEmail));
    showDataForm.append('password', cookies.get(typeCookiePassw));

    showDataForm.append('idPage', idPage);
    showDataForm.append('idUser', idUser);
    showDataForm.append('typeDiscount', typeDiscount);
    showDataForm.append('discount', discount);
    showDataForm.append('lock_', lock_);
    showDataForm.append('version', version);
    showDataForm.append('signature', signature);
    showDataForm.append('typeBudget', typeBudget);
    showDataForm.append('chanceClose', chanceClose);
    showDataForm.append('proposal', proposal);
    showDataForm.append('client', client);
    showDataForm.append('contact', contact);
    
    showDataForm.append('nameClient', nameClient);
    showDataForm.append('corporateName', corporateName);
    showDataForm.append('cnpj', cnpj);
    showDataForm.append('actingArea', actingArea);
    showDataForm.append('customerSince', customerSince);
    showDataForm.append('contactName', contactName);
    showDataForm.append('contactEmail', contactEmail);
    showDataForm.append('contactPhone', contactPhone);
    showDataForm.append('contactOffice', contactOffice);

    showDataForm.append('subject', subject);
    showDataForm.append('status', status);
    showDataForm.append('dateStatus', dateStatus);
    showDataForm.append('newStatus', newStatus);
    showDataForm.append('newColor', newColor);
    showDataForm.append('billed', billed);
    showDataForm.append('dueDate', dueDate);
    showDataForm.append('motive', motive);
    showDataForm.append('newMotive', newMotive);
    showDataForm.append('obs', obs);
    showDataForm.append('dateStart', dateStart);
    showDataForm.append('dateEnd', dateEnd);
    showDataForm.append('mountingStart', mountingStart);
    showDataForm.append('mountingEnd', mountingEnd);
    
    showDataForm.append('showGroup', showGroup);

    listCategory.forEach(element => {
        showDataForm.append('category_id[]', element.id);
        showDataForm.append('category_idCategory[]', element.id_category);
        showDataForm.append('category_new[]', element.new_category);
    });

    textFixed.forEach(element => {
        showDataForm.append('textFixed_id[]', element.id);
        showDataForm.append('textFixed_title[]', element.title);
        showDataForm.append('textFixed_text[]', element.text);
    });

    services.forEach((element, index) => {
        showDataForm.append('service_id[]', element.id);
        showDataForm.append('service_id_edit[]', element.id_edit);
        showDataForm.append('service_title[]', element.title);

        element.list_services.forEach((element_1, index_1) =>{

            element_1.service.forEach((element_2, index_2) =>{
                showDataForm.append('service_data_index_0[]', index);
                showDataForm.append('service_data_index_1[]', index_2);

                showDataForm.append('service_data_index[]', index);
                showDataForm.append('service_data_id[]', element_2.id);
                showDataForm.append('service_data_id_service[]', element_2.id_service);
                showDataForm.append('service_data_title[]', element_2.title);
                showDataForm.append('service_data_qtd[]', element_2.qtd);
                showDataForm.append('service_data_tax[]', element_2.tax);
                showDataForm.append('service_data_cash[]', element_2.cash);
                showDataForm.append('service_data_text[]', element_2.text);

                showDataForm.append('link_qtd[]', element_2.list_link.length);
                element_2.list_link.forEach((element_3, index_3) => {                    
                    showDataForm.append('link_index_0_' + index + '_' + index_2 + '_' + index_3, index);
                    showDataForm.append('link_index_1_' + index + '_' + index_2 + '_' + index_3, index_2);

                    showDataForm.append('link_id_' + index + '_' + index_2 + '_' + index_3, element_3.id);
                    showDataForm.append('link_type_' + index + '_' + index_2 + '_' + index_3, element_3.type);
                    showDataForm.append('link_id_portfolio_' + index + '_' + index_2 + '_' + index_3, element_3.portfolio_id);
                    showDataForm.append('link_link_' + index + '_' + index_2 + '_' + index_3, element_3.link);
                });

            });        
        })
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data : showDataForm
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('client', response.data.client);
        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('budget_signature', response.data.budget_signature);
        SetListDataSingle('budget_text_fixed', response.data.budget_text_fixed);
        SetListDataSingle('budget_status', response.data.budget_status);
        SetListDataSingle('budget_historic', response.data.budget_historic);
        SetListDataSingle('budget_category', response.data.budget_category);
        SetListDataSingle('proposal', response.data.proposal);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data); 
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);
    //     SetListPag('currentPageId', data.idPage);

    //     SetListDataSingle('client', data.client);
    //     SetListDataSingle('budget', data.budget);
    //     SetListDataSingle('budget_signature', data.budget_signature);
    //     SetListDataSingle('budget_text_fixed', data.budget_text_fixed);
    //     SetListDataSingle('budget_status', data.budget_status);
    //     SetListDataSingle('budget_historic', data.budget_historic);
    //     SetListDataSingle('budget_category', data.budget_category);
    //     SetListDataSingle('proposal', data.proposal);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Alt status budget
export function Reg_AltStatusBudget(idBudget, idUser, code, status, dateStatus, newStatus, color, currentStatus, motive, newMotive, obsMotive, billed, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'alt_status_budget');

    showDataForm.append('idBudget', idBudget);
    showDataForm.append('idUser', idUser);
    showDataForm.append('code', code);
    showDataForm.append('status', status);
    showDataForm.append('dateStatus', dateStatus);
    showDataForm.append('newStatus', newStatus);
    showDataForm.append('color', color);
    showDataForm.append('currentStatus', currentStatus);
    showDataForm.append('motive', motive);
    showDataForm.append('newMotive', newMotive);
    showDataForm.append('obsMotive', obsMotive);
    showDataForm.append('billed', billed);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('budget', response.data.budget);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget', data.budget);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Alt open budget
export function Reg_OpenBudget(idBudget, idUser, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'open_budget');

    showDataForm.append('idBudget', idBudget);
    showDataForm.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {
        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('budget_historic', response.data.budget_historic);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget', data.budget);
    //     SetListDataSingle('budget_historic', data.budget_historic);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Alt version budget
export function Reg_AltVersionBudget(idBudget, version, userId, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'alt_version_budget');

    showDataForm.append('idBudget', idBudget);
    showDataForm.append('version', version);
    showDataForm.append('userId', userId);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {
        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('budget_historic', response.data.budget_historic);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget', data.budget);
    //     SetListDataSingle('budget_historic', data.budget_historic);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Copy Budget
export function Reg_CopyBudget(id, idUser, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'copy_budget');

    showDataForm.append('id', id);
    showDataForm.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListDataSingle('budget', response.data.budget);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
        
    //     cookies.set(typeCookiePageId, data.idPage, '/', cookiesRegister);
    //     SetListDataSingle('budget', data.budget);
    //     SetListPag('currentPageId', data.idPage);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Dowonload csv
export function Reg_DownloadCsv(idUser, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'access_dash');
    showDataForm.append('edit_data', 'download_csv');

    showDataForm.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/downloadCsv.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data); 
        CallbackSuccess();
        
        let url = process.env.REACT_APP_API_URL + 'php/budget_table.csv';
        let link = document.createElement('a');
        link.href = url;

        link.download = 'budget_table.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/downloadCsv.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);     
    //     CallbackSuccess();
        
    //     let url = process.env.REACT_APP_API_URL + 'php/budget_table.csv';
    //     let link = document.createElement('a');
    //     link.href = url;

    //     link.download = 'budget_table.csv';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// budget - Tasks checked
export function Reg_TasksChecked(userId, id, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'tasks_checked');

    showDataForm.append('userId', userId);
    showDataForm.append('id', id);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('budget_task', response.data.budget_task);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget_task', data.budget_task);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Updates
export function Reg_Updates(userId, idPage, linkMonday, folderDrive, gestResp, directionCreation, text, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'budget');
    showDataForm.append('edit_data', 'updates');

    showDataForm.append('userId', userId);
    showDataForm.append('idPage', idPage);
    showDataForm.append('link_monday', linkMonday);
    showDataForm.append('folder_drive', folderDrive);
    showDataForm.append('gest_resp', gestResp);
    showDataForm.append('direction_creation', directionCreation);
    showDataForm.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('budget_update', response.data.budget_update);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('budget_update', data.budget_update);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Upadates emails
export function Reg_UpdatesEmail(userId, idPage, subject, listEmail, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('edit_data', 'updates');

    showDataForm.append('userId', userId);
    showDataForm.append('idPage', idPage);
    showDataForm.append('subject', subject);
    listEmail.forEach(element => {
        showDataForm.append('email[]', element.email);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/send_email_updates.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data.budget_update_email); 
        switch (response.data.type) {
            case "Email enviado com sucesso":
                    SetListDataSingle('budget_update_email', response.data.budget_update_email);
                    CallbackSuccess();
                break;
        
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/send_email_updates.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     switch (data.type) {
    //         case "Email enviado com sucesso":
    //                 SetListDataSingle('budget_update_email', data.budget_update_email);
    //                 CallbackSuccess();
    //             break;
        
    //         default:
    //                 CallbackError();
    //             break;
    //     }
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}

// Add Tasks
export function Reg_Tasks(type, userId, idPage, responsible, start, end, priority, title, text, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'list_tasks');
    showDataForm.append('edit_data', 'tasks');

    showDataForm.append('userId', userId);
    showDataForm.append('idPage', idPage);
    showDataForm.append('type', type);
    showDataForm.append('start', start);
    showDataForm.append('end', end);
    showDataForm.append('priority', priority);
    showDataForm.append('title', title);
    showDataForm.append('text', text);

    responsible.forEach((elem, index) => {
        showDataForm.append('id_responsible[]', elem.value);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('list_tasks', response.data.list_tasks);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('list_tasks', data.list_tasks);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const showDataForm = new FormData();

    showDataForm.append('type_page', 'access_dash');
    showDataForm.append('edit_data', 'edit_user');

    showDataForm.append('id', id);
    showDataForm.append('name', name);
    showDataForm.append('email', email);
    showDataForm.append('pass', pass);
    showDataForm.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        const newData = response.data.access_dash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('access_dash', data.access_dash);
    //     const newData = data.access_dash.find(item => item.id == id);
    //     cookies.set(typeCookieEmail, email, '/', cookiesRegister);
    //     if(pass !=''){
    //         cookies.set(typeCookiePassw, data.pass, '/', cookiesRegister);
    //     }
    //     SetUserData('id', newData['id']);
    //     SetUserData('file', newData['file']);
    //     SetUserData('name', newData['name']);
    //     SetUserData('email', newData['email']);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}


// Tasks
export function Reg_MenuTasks(userId, id, typeTasks, responsible, start, finalDate, priority, dataId, title, text, status, motive, feedback, newStart, newEnd, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'list_tasks');
    showDataForm.append('edit_data', 'edit_or_register');

    showDataForm.append('userId', userId);
    showDataForm.append('id', id);
    showDataForm.append('type', typeTasks);
    showDataForm.append('start', start);
    showDataForm.append('finalDate', finalDate);
    showDataForm.append('priority', priority);
    showDataForm.append('dataId', dataId);
    showDataForm.append('title', title);
    showDataForm.append('text', text);
    showDataForm.append('status', status);
    showDataForm.append('motive', motive);
    showDataForm.append('feedback', feedback);
    showDataForm.append('newStart', newStart);
    showDataForm.append('newEnd', newEnd);

    if(responsible.length > 0){
        responsible.forEach((elem, index) => {
            showDataForm.append('id_responsible[]', elem.value);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('list_tasks', response.data.list_tasks);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('list_tasks', data.list_tasks);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}
// Tasks - status busget
export function Reg_TasksStatus(userId, id, status, motive, feedback, newStart, newEnd, CallbackSuccess, CallbackError){
    const showDataForm = new FormData();

    showDataForm.append('type_page', 'list_tasks');
    showDataForm.append('edit_data', 'alt_status');

    showDataForm.append('userId', userId);
    showDataForm.append('id', id);
    showDataForm.append('status', status);
    showDataForm.append('motive', motive);
    showDataForm.append('feedback', feedback);
    showDataForm.append('newStart', newStart);
    showDataForm.append('newEnd', newEnd);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : showDataForm
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('list_tasks', response.data.list_tasks);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })

    // fetch(process.env.REACT_APP_API_URL + 'php/register.php', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     body: new URLSearchParams(showDataForm)
    // })
    // .then(response => {
    //     if (response.ok) {
    //         return response.json();
    //     } else {
    //         throw new Error('Erro na resposta');
    //     }
    // })
    // .then(data => {
    //     console.log(data);    
    //     SetListDataSingle('list_tasks', data.list_tasks);
    //     CallbackSuccess();
    // })
    // .catch(error => {
    //     console.log(error);
    //     CallbackError();
    // });
}